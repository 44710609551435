<template>
  <div class="main">
    <van-nav-bar
        title="添加人员"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
     />
    <div class="main">
      <div class="jian"></div>
      <div class="block1">
        <van-cell-group>
          <van-field v-model="post.trueName" label="姓名" placeholder="请输入用户名" input-align="right" label-align="left" label-class="add_label" required/>
          <van-field name="radio" label="性别" input-align="right"  label-class="add_label">
            <template #input>
              <van-radio-group v-model="post.sex" direction="horizontal">
                <van-radio name="男">男</van-radio>
                <van-radio name="女">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="post.phone" label="手机号码" placeholder="请输入手机号码" input-align="right"  label-class="add_label" required type="tel"/>
          <van-field v-model="post.idCard" label="执法证号" placeholder="请输入执法证编号" input-align="right"  label-class="add_label"/>
        </van-cell-group>

      </div>

      <div class="jian"></div>
      <div style="margin-top: 2rem" v-if="!post.id"><van-button plain hairline type="info" style="width: 50vw" @click="add()">确认添加</van-button></div>
      <div style="margin-top: 2rem" v-if="post.id"><van-button plain hairline type="info" style="width: 50vw" @click="memberEdit()">确认修改</van-button></div>
    </div>


  </div>

</template>

<script>
import { getMemberAdd,getMemberEdit } from '@/api/get'
import Vue from 'vue';
import {NavBar, Col, Row, Icon, Cell, CellGroup, Popup, Field, RadioGroup, Radio, button, Toast} from 'vant';
Vue.use(Col);
Vue.use(Row);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(button);
export default {
  name: "xiafa",
  data(){
    return{
      post:{
        id:0,
        trueName:'',
        phone:'',
        sex:'男',
        idCard:'',
      },
      showPickerDate: false,
      currentDate: new Date(),
    }},
  mounted() {
    if(localStorage.userType!=50){
      this.$router.push({ path: '/', query: {}})
      return
    }
    this.post.id=this.$route.query.id
    this.post.trueName=this.$route.query.trueName
    this.post.phone=this.$route.query.phone
    this.post.sex=this.$route.query.sex
    if(this.$route.query.sex){
      this.post.sex=this.$route.query.sex
    }else{
      this.post.sex="男"
    }
    this.post.idCard=this.$route.query.idCard
    //this.queue(this.$route.meta.id,this.$route.meta.title,this.$route.path)
    },
  methods:{
    memberEdit(){
      if(!this.post.trueName){
        Toast("请输入姓名")
        return
      }
      if(!this.post.phone){
        Toast("请输入电话")
        return
      }
      if(!this.tel(this.post.phone)){
        Toast("请输入正确的号码")
        return
      }
      getMemberEdit(this.post).then(res => {
        if (res.data.code === 200){
          Toast("已修改")
          this.$router.push({ path: '/inspector', query: {}})
        }
      })
    },
    add(){
      if(!this.post.trueName){
        Toast("请输入姓名")
        return
      }
      if(!this.post.phone){
        Toast("请输入电话")
        return
      }

      if(!this.tel(this.post.phone)){
        Toast("请输入正确的号码")
        return
      }
      getMemberAdd(this.post).then(res => {
        if (res.data.code === 200){
          this.$router.push({ path: '/inspector', query: {}})
        }
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    tel(p){
      var phone = p;
      var regex = /^(1[0-9]{10})$/;
      return regex.test(phone)
         }
  }
}
</script>
<style>
.add_label{font-size: 1.0rem}
</style>
<style scoped lang="css" src="./assets/index.css" />